import { AuthGate } from '@/config/auth';
import { PERMISSION_ORGANIZATIONAL_STRUCTURE_CHANGE_COMPANY, PERMISSION_USERS_ADD_BASEUSER } from '@/config/permissions';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import BusinessIcon from '@mui/icons-material/Business';
import HouseIcon from '@mui/icons-material/House';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import SettingsIcon from '@mui/icons-material/Settings';
import { Button, IconButton } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import * as React from 'react';
import { createContext, useContext, useState } from 'react';

export const drawerWidth = 280;
export const drawerClosedWidth = 88;

const SidebarContext = createContext();

export const SidebarProvider = ({children}) => {
  const [isOpen, setIsOpen] = useState(
    localStorage.getItem('sidebar_state') != 'closed'
  );

  const toggleSidebar = () => {
    const newState = !isOpen;
    setIsOpen(newState);
    //localStorage é meio bugado quando não armazena string
    localStorage.setItem('sidebar_state', newState ? 'open' : 'closed');
  };

  return (
    <SidebarContext.Provider value={{isOpen, toggleSidebar}}>
      {children}
    </SidebarContext.Provider>
  );
};

export const useSidebar = () => {
  const context = useContext(SidebarContext);
  if (context === undefined) {
    throw new Error('useSidebar deve estar dentro de um SidebarProvider');
  }
  return context;
};

export function Sidebar() {
  const {isOpen, toggleSidebar} = useSidebar();

  const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: theme.spacing(1, 2),
    color: theme.palette.neutral[0],
    ...theme.mixins.toolbar,
    position: isOpen ? 'relative' : 'relative',
    top: 0,
    right: 0,
  }));

  //MUDAR BOTOES DA SIDEBAR AQUI
  const drawer_contents = (
    <Box px="16px">
      <DrawerHeader sx={{padding: '8px !important'}}>
        <IconButton disableRipple onClick={toggleSidebar} color="inherit">
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
            gap={2}
          >
            {isOpen ? (
              <Box>
                <MenuOpenIcon sx={{marginRight: '14px'}} />
                <Image
                  src="/png/adian_alternate.png"
                  alt="Adian Plataform Logo"
                  height={20}
                  width={150}
                />
              </Box>
            ) : (
              <Box>
                <MenuOpenIcon sx={{transform: 'rotate(180deg)'}} />
              </Box>
            )}
          </Box>
        </IconButton>
      </DrawerHeader>
      <SidebarButton name="Início" Icon={HouseIcon} path="/" />
      <SidebarButton
        name="Adicionar Objetivo"
        Icon={AddCircleIcon}
        path="/criar-objetivo"
      />
      <Divider light={true} />
      <AuthGate permissions={[PERMISSION_ORGANIZATIONAL_STRUCTURE_CHANGE_COMPANY, PERMISSION_USERS_ADD_BASEUSER]}>
        <SidebarGroup name="Configurações" Icon={SettingsIcon}>
          <SidebarButton name="Empresa" Icon={BusinessIcon} path="/company" />
          <SidebarButton name="Usuários" Icon={AccountCircleIcon} path="/users" />
        </SidebarGroup>
      </AuthGate>
    </Box>
  );

  return (
    <>
      <Drawer
        variant="temporary"
        open={isOpen}
        onClose={toggleSidebar}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: {xs: 'block', sm: 'none'},
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: isOpen ? drawerWidth : drawerClosedWidth,
            transition: 'width 0.3s',
            overflowX: 'hidden',
            zIndex: 3,
          },
        }}
      >
        {drawer_contents}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: {xs: 'none', sm: 'block'},
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: isOpen ? drawerWidth : drawerClosedWidth,
            transition: 'width 0.3s',
            overflowX: 'hidden',
            position: 'initial',
            height: '100vh',
            zIndex: 3,
          },
        }}
        open
      >
        {drawer_contents}
      </Drawer>
    </>
  );
}

function SidebarButton({name, Icon, path, isIndented, selectedCallback}) {
  const router = useRouter();
  const buttonSelected = router.pathname.endsWith(path);
  const {isOpen} = useSidebar();

  React.useEffect(() => {
    if (buttonSelected && !!selectedCallback) {
      selectedCallback();
    }
  }, [router.pathname, buttonSelected, selectedCallback]);

  let bg = buttonSelected ? 'primary.darker' : null;
  const button = (
    <Button
      fullWidth
      size="large"
      variant={'text'}
      startIcon={
        <Icon sx={{marginLeft: '18px', marginRight: isOpen ? '18px' : '0'}} />
      }
      sx={{
        color: 'neutral.0',
        justifyContent: 'left',
        pl: isIndented ? '56px' : '4px',
        py: 0,
        height: '56px',
        minWidth: '56px',
        backgroundColor: bg,
        textWrap: 'nowrap',
      }}
    >
      <span
        style={{
          opacity: isOpen ? 1 : 0,
          transition: 'opacity 0.8s',
        }}
      >
        {isOpen ? name : ''}
      </span>
    </Button>
  );
  if (path) return <Link href={path}>{button}</Link>;

  return button;
}

function SidebarGroup({name, Icon, children}) {
  const {isOpen} = useSidebar();
  const [isExpanded, toggleExpanded] = React.useState(false);
  const setExpanded = () => {
    toggleExpanded(true);
  };
  const handleChange = (event, expanded) => {
    toggleExpanded(expanded);
  };
  return (
    <Accordion
      disableGutters={true}
      expanded={isExpanded}
      onChange={handleChange}
      sx={{
        backgroundColor: 'transparent',
        boxShadow: 'none',
        border: 0,
        display: 'flex',
        flexDirection: 'column',
        '&:before': {display: 'none'},
      }}
    >
      <AccordionSummary
        sx={{
          padding: 0,
          margin: 0,
          '& .MuiAccordionSummary-content': {display: 'block', margin: 0},
          justifyContent: 'left',
        }}
      >
        <SidebarButton name={name} Icon={Icon} />
      </AccordionSummary>
      <AccordionDetails sx={{padding: 0, border: 0}}>
        {React.Children.map(children, (child) =>
          React.cloneElement(child, {
            isIndented: isOpen,
            selectedCallback: setExpanded,
          })
        )}
      </AccordionDetails>
    </Accordion>
  );
}
