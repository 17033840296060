import MenuIcon from '@mui/icons-material/Menu';
import {ClickAwayListener, useMediaQuery} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import {useTheme} from '@mui/material/styles';
import {signOut, useSession} from 'next-auth/react';
import Link from 'next/link';
import * as React from 'react';
import {drawerWidth, useSidebar} from './sidebar';

export default function Navbar() {
  const {data: session} = useSession();
  const {isOpen, toggleSidebar} = useSidebar();
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleOpenUserMenu = (event) => {
    event.stopPropagation();
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = (event) => {
    event.stopPropagation();
    setAnchorElUser(null);
  };

  if (!isMobile) {
    return (
      <Box
        onClick={handleOpenUserMenu}
        sx={{
          flexGrow: 0,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          position: 'fixed',
          bottom: '25px',
          left: '25px',
          zIndex: 1,
        }}
      >
        <Tooltip title="Abrir configurações">
          <IconButton sx={{p: 0}}>
            <Avatar alt="Profile Picture" src={session?.user?.image} />
          </IconButton>
        </Tooltip>
        <Typography
          noWrap
          fontSize="16px"
          fontWeight="600"
          sx={{
            px: '24px',
            transition: 'width 0.3s, opacity 0.01s',
            width: isOpen ? `${drawerWidth - 66}px` : 0,
            opacity: isOpen ? 1 : 0,
            color: 'neutral.0',
          }}
        >
          {session?.user?.name}
        </Typography>
        <ProfileMenu
          anchorElUser={anchorElUser}
          handleCloseUserMenu={handleCloseUserMenu}
        />
      </Box>
    );
  }

  return (
    <AppBar
      position="fixed"
      color="primary"
      sx={{
        width: {sm: `calc(100% - ${drawerWidth}px)`},
        ml: {sm: `${drawerWidth}px`},
      }}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={toggleSidebar}
          sx={{mr: 2, flexGrow: 0}}
        >
          <MenuIcon />
        </IconButton>
        <Box sx={{flexGrow: 1}}></Box>
        <Box
          onClick={handleOpenUserMenu}
          sx={{
            flexGrow: 0,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Typography
            fontSize="16px"
            fontWeight="600"
            sx={{px: '24px', color: 'neutral.0'}}
          >
            {session?.user?.name}
          </Typography>
          <Tooltip title="Abrir configurações">
            <IconButton sx={{p: 0}}>
              <Avatar alt="Profile Picture" src={session?.user?.picture} />
            </IconButton>
          </Tooltip>
          <ProfileMenu
            anchorElUser={anchorElUser}
            handleCloseUserMenu={handleCloseUserMenu}
          />
        </Box>
      </Toolbar>
    </AppBar>
  );
}

function ProfileMenu({anchorElUser, handleCloseUserMenu}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <ClickAwayListener onClickAway={handleCloseUserMenu}>
      <Menu
        sx={{mt: '45px'}}
        PaperProps={{
          sx: {
            minWidth: '150px',
          },
        }}
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: isMobile ? 'top' : 'bottom',
          horizontal: isMobile ? 'right' : 'left',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {/*<ProfileMenuIcon
          name="Perfil"
          path="profile"
          handleCloseUserMenu={handleCloseUserMenu}
        />
      <Divider />*/}
        <ProfileMenuIcon
          name="Logout"
          path="logout"
          handleCloseUserMenu={handleCloseUserMenu}
        />
      </Menu>
    </ClickAwayListener>
  );
}

function ProfileMenuIcon({name, path, handleCloseUserMenu}) {
  if (path == 'logout') {
    return (
      <MenuItem key={name} onClick={signOut}>
        <Typography align="left" textDecoration="none">
          {name}
        </Typography>
      </MenuItem>
    );
  }
  return (
    <Link href={path} style={{textDecoration: 'none'}}>
      <MenuItem key={name} onClick={handleCloseUserMenu}>
        <Typography align="left" textDecoration="none">
          {name}
        </Typography>
      </MenuItem>
    </Link>
  );
}
